/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import NPInputEdit from './components/NPInputEdit';
import { InputViewMode } from '../../../components/inputViewMode/InputViewMode';
import { isFunction } from '../../../../../../utils/functions/validator/functionValidator';

const NPInput = props => {
  const {
    editModeFixed,
    value,
    editTrigger,
    onConfirm,
    canEdit,
    hideCanEditIcon,
    keyBindEvents,
    onChangeViewMode,
    inputType
  } = props;
  const [inputEditMode, setInputEditMode] = useState(editModeFixed);

  useEffect(() => {
    if (!isFunction(onChangeViewMode)) {
      return;
    }
    onChangeViewMode(inputEditMode ? 'edit' : 'view');
  }, [inputEditMode, onChangeViewMode]);

  const [originalInputValue, setOriginalInputValue] = useState(value);

  const valueIsChanged = useMemo(
    () => originalInputValue !== value,
    [originalInputValue, value]
  );
  const canOnConfirm = useMemo(
    () => onConfirm && valueIsChanged,
    [onConfirm, valueIsChanged]
  );

  useEffect(() => {
    const setInitialStatus = () => {
      setInputEditMode(editModeFixed);
    };
    setInitialStatus();
  }, [editModeFixed]);

  const onClickEdit = () => {
    setOriginalInputValue(value);
    setInputEditMode(true);
  };

  const onBlurInputEdit = () => {
    if (canOnConfirm) {
      onConfirm(value);
    }
    const canSetEditModeToFalse =
      (onConfirm && valueIsChanged) || !editModeFixed;
    if (canSetEditModeToFalse) {
      setInputEditMode(false);
    }
  };

  const onKeyPressInputEdit = event => {
    if (!keyBindEvents) {
      return;
    }
    if (event.key === 'Enter') {
      setInputEditMode(false);
      if (canOnConfirm) {
        onConfirm(value);
      }
    }
  };

  return inputEditMode ? (
    <NPInputEdit
      onKeyPress={onKeyPressInputEdit}
      onBlur={onBlurInputEdit}
      canEdit={canEdit}
      {...props}
    />
  ) : (
    <InputViewMode
      canEdit={canEdit}
      editTrigger={editTrigger}
      onClickEdit={onClickEdit}
      inputType={inputType}
      hideCanEditIcon={hideCanEditIcon}
      {...props}
    />
  );
};

NPInput.propTypes = {
  editModeFixed: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  editTrigger: PropTypes.arrayOf(String),
  size: PropTypes.string,
  onConfirm: PropTypes.func,
  onChangeViewMode: PropTypes.func,
  canEdit: PropTypes.bool,
  hideCanEditIcon: PropTypes.bool,
  keyBindEvents: PropTypes.bool,
  inputType: PropTypes.string
};
NPInput.defaultProps = {
  editModeFixed: false,
  value: undefined,
  onChange: undefined,
  onChangeViewMode: undefined,
  onConfirm: undefined,
  editTrigger: undefined,
  size: undefined,
  canEdit: true,
  hideCanEditIcon: false,
  keyBindEvents: false,
  inputType: 'input'
};

export default NPInput;
