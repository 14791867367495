import { message } from 'antd';

const snackbar = props => {
  const { type, ...restProps } = props;
  const config = {
    top: 10,
    duration: 2,
    maxCount: 3
  };
  message.config(config);
  switch (type) {
    case 'success':
      message.success(restProps);
      break;
    case 'error':
      message.error(restProps);
      break;
    case 'info':
      message.info(restProps);
      break;
    case 'warning':
      message.warning(restProps);
      break;
    default:
      message.open(restProps);
      break;
  }
};

export { snackbar };
