import React from 'react';
import PropTypes from 'prop-types';
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';

const PasswordIcon = ({ setShowPassword, showPassword }) => {
  const handleOnClickPasswordIcon = () => {
    setShowPassword(!showPassword);
  };

  const showPasswordIconStyles = {
    color: 'var(--primary-color)'
  };

  return (
    <div role="presentation" onMouseDown={handleOnClickPasswordIcon}>
      {showPassword ? (
        <EyeOutlined style={showPasswordIconStyles} />
      ) : (
        <EyeInvisibleOutlined />
      )}
    </div>
  );
};

PasswordIcon.propTypes = {
  setShowPassword: PropTypes.func.isRequired,
  showPassword: PropTypes.bool
};

PasswordIcon.defaultProps = {
  showPassword: false
};

export default PasswordIcon;
