import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Input as InputAntd, InputNumber } from 'antd';
import {
  blockInvalidCharOnPaste,
  blockInvalidCharOnKeyDown
} from '../../../../../../../utils/functions/validator/inputValidator';
import { InputSuffix } from './NPInputSuffix';
import { isNumber } from '../../../../../../../utils/functions/validator/numberValidator';
import useTranslation from '../../../../../../../i18n/hooks/useTranslation';
import { isObjectWithValues } from '../../../../../../../utils/functions/validator/objectValidator';
import valueFormatterByDataSheet from '../../../../../table/utils/function/valueFormatterByDataSheet';

const NPInputEdit = props => {
  const {
    size,
    prefix,
    suffix,
    placeholder,
    autoComplete,
    onChange,
    type,
    value,
    defaultValue,
    disabled,
    className,
    name,
    min,
    toBeChecked,
    required,
    maxLength,
    inputId,
    allowClear,
    onBlur,
    onKeyPress,
    max,
    autoFocus,
    hideArrows,
    cleanValue,
    step,
    decimalSeparator,
    parser,
    precision,
    fullWidth
  } = props;
  const { translation } = useTranslation();

  const [inputType, setInputType] = useState('text');
  const [showPassword, setShowPassword] = useState(false);

  const inputRef = useRef(null);

  useEffect(() => {
    const checkToFocus = () => {
      const canFocus =
        autoFocus && isObjectWithValues(inputRef) && inputRef.current;
      if (canFocus) {
        inputRef.current.focus();
      }
    };

    checkToFocus();
  }, [autoFocus]);

  useEffect(() => {
    const handleOnChangeType = () => {
      const isInputTypePassword = type === 'password';
      if (!isInputTypePassword) {
        setInputType(type);
        return;
      }
      if (showPassword) {
        setInputType('text');
        inputRef.current.input.type = 'text';
      } else {
        setInputType('password');
        inputRef.current.input.type = 'password';
      }
    };
    handleOnChangeType();
  }, [showPassword, type]);

  const handleOnChange = ({ target: { value: newValue } }) => {
    if (!onChange) {
      return;
    }
    if (isNumber(min)) {
      const isValueMin = parseFloat(newValue) < min;
      if (isValueMin) {
        return;
      }
    }
    if (isNumber(max)) {
      const isValueMax = parseFloat(newValue) > max;
      if (isValueMax) {
        return;
      }
    }
    if (maxLength) {
      const isValueMaxLength = newValue.toString().length > maxLength;
      if (isValueMaxLength) {
        return;
      }
    }
    onChange(cleanValue ? newValue : { target: { value: newValue } });
  };

  const parserByType = valueToParse => {
    switch (type) {
      case 'int':
      case 'float':
        return valueFormatterByDataSheet({
          value: valueToParse,
          valueFormat: type,
          maxDecimals: precision
        });
      default:
        return value;
    }
  };

  return (
    <div
      className={`np-input np-input-edit-mode ${size || ''} ${
        fullWidth ? 'full-width' : ''
      }`}
    >
      {inputType === 'int' || inputType === 'float' ? (
        <InputNumber
          value={value}
          onChange={onChange}
          decimalSeparator={decimalSeparator}
          parser={parser || parserByType}
          precision={precision}
          autoFocus={autoFocus}
          defaultValue={defaultValue}
          disabled={disabled}
          max={max}
          min={min}
          size={size}
          step={step}
          ref={inputRef}
          id={inputId}
          onBlur={onBlur}
          onKeyPress={onKeyPress}
        />
      ) : (
        <InputAntd
          onBlur={onBlur}
          onKeyPress={onKeyPress}
          ref={inputRef}
          name={name}
          id={inputId}
          size={size}
          prefix={prefix}
          suffix={
            <InputSuffix
              suffix={suffix}
              type={type}
              setShowPassword={setShowPassword}
              showPassword={showPassword}
            />
          }
          type={inputType}
          placeholder={translation(placeholder)}
          autoComplete={autoComplete}
          onChange={handleOnChange}
          value={value}
          defaultValue={defaultValue}
          disabled={disabled}
          className={`${className || ''} ${required ? 'required-input' : ''} ${
            hideArrows ? 'hide-arrows' : ''
          }`}
          min={min}
          max={max}
          onKeyDown={
            toBeChecked
              ? expression => blockInvalidCharOnKeyDown(expression, type)
              : undefined
          }
          onPaste={
            toBeChecked
              ? expression => blockInvalidCharOnPaste(expression, type)
              : undefined
          }
          maxLength={maxLength}
          allowClear={allowClear}
        />
      )}
    </div>
  );
};

NPInputEdit.propTypes = {
  name: PropTypes.string,
  size: PropTypes.string,
  inputId: PropTypes.string,
  prefix: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  suffix: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  placeholder: PropTypes.string,
  autoComplete: PropTypes.string,
  type: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  defaultValue: PropTypes.string,
  disabled: PropTypes.bool,
  min: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  toBeChecked: PropTypes.bool,
  required: PropTypes.bool,
  maxLength: PropTypes.number,
  allowClear: PropTypes.bool,
  onBlur: PropTypes.func,
  onKeyPress: PropTypes.func,
  max: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  autoFocus: PropTypes.bool,
  hideArrows: PropTypes.bool,
  cleanValue: PropTypes.bool,
  step: PropTypes.number,
  decimalSeparator: PropTypes.string,
  parser: PropTypes.func,
  precision: PropTypes.number,
  fullWidth: PropTypes.bool
};

NPInputEdit.defaultProps = {
  name: undefined,
  inputId: undefined,
  size: undefined,
  prefix: undefined,
  suffix: undefined,
  placeholder: undefined,
  autoComplete: 'none',
  type: 'text',
  onChange: undefined,
  value: undefined,
  defaultValue: undefined,
  disabled: undefined,
  className: undefined,
  min: undefined,
  toBeChecked: false,
  required: false,
  maxLength: undefined,
  allowClear: false,
  onBlur: undefined,
  onKeyPress: undefined,
  max: undefined,
  autoFocus: false,
  hideArrows: false,
  cleanValue: false,
  step: undefined,
  decimalSeparator: undefined,
  parser: undefined,
  precision: undefined,
  fullWidth: false
};

export default NPInputEdit;
