import { snackbar } from '../../../components/shared/info/snackbar/snackbar';


const notificationInvalid = () => {
  snackbar({
    type: 'error',
    content: 'No se permiten caracteres no numéricos'
  });
};

const blockInvalidCharOnPaste = expression => {
  const valueOnPaste = expression.clipboardData.getData('text/plain');
  const arrayFromValuePaste = valueOnPaste.split('');
  if (
    arrayFromValuePaste.includes('+', 'e', 'E', '+', '-', 'a', 'A', '+', '.')
  ) {
    notificationInvalid();
    expression.preventDefault();
  }
};

const blockInvalidCharOnKeyDown = expression => {
  if (['e', 'E', '+', '-', 'a', 'A', '.'].includes(expression.key)) {
    notificationInvalid();
    expression.preventDefault();
  }
};

export { blockInvalidCharOnPaste, blockInvalidCharOnKeyDown };
