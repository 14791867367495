import { toInt } from '../../../../../utils/functions/formatter/numberFormatter';
import { isStringWithCharacters } from '../../../../../utils/functions/validator/stringValidator';

const valueFormatterByDataSheet = ({ value, valueFormat, maxDecimals }) => {
  if (!valueFormat) {
    return value;
  }
  switch (valueFormat) {
    case 'float': {
      if (!isStringWithCharacters(value)) {
        return parseFloat(value);
      }
      if (value.indexOf('.') === -1 && value.indexOf(',') === -1) {
        return parseFloat(value);
      }
      const valueWithAllDots = value.replace(/,/g, '.');
      const [decimals, ...numberBeforeDecimals] = valueWithAllDots
        .split('.')
        .reverse();
      const valueWithOutDots = numberBeforeDecimals.reverse().join('');

      const decimalsFormatter = maxDecimals
        ? decimals.substring(0, maxDecimals)
        : decimals;

      return parseFloat(`${valueWithOutDots}.${decimalsFormatter || 0}`);
    }
    case 'int':
      return toInt(value);
    default:
      return value;
  }
};

export default valueFormatterByDataSheet;
