/* eslint-disable react/jsx-props-no-spreading */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Label from '../label/Label';
import NPInput from './components/NPInput/NPInput';
import { isStringWithCharacters } from '../../../../utils/functions/validator/stringValidator';
import useTranslation from '../../../../i18n/hooks/useTranslation';

const Input = props => {
  const {
    label,
    labelPosition,
    size,
    ellipsis,
    layout,
    showInfo,
    tooltipTitle,
    showTooltip,
    boldLabel,
    labelTextTransform,
    ...inputProps
  } = props;

  const { translation } = useTranslation();

  const labelDisplay = useMemo(
    () => (isStringWithCharacters(label) ? translation(label) : label),
    [label, translation]
  );

  return labelDisplay ? (
    <Label
      label={labelDisplay}
      size={size}
      ellipsis={ellipsis}
      labelPosition={labelPosition}
      layout={layout}
      showInfo={showInfo}
      tooltipTitle={tooltipTitle}
      showTooltip={showTooltip}
      boldLabel={boldLabel}
      labelTextTransform={labelTextTransform}
    >
      <NPInput {...inputProps} size={size} />
    </Label>
  ) : (
    <NPInput {...inputProps} size={size} />
  );
};

Input.propTypes = {
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.node
  ]),
  size: PropTypes.string,
  ellipsis: PropTypes.bool,
  labelPosition: PropTypes.string,
  layout: PropTypes.objectOf(Object),
  showInfo: PropTypes.bool,
  tooltipTitle: PropTypes.string,
  boldLabel: PropTypes.bool,
  showTooltip: PropTypes.bool,
  labelTextTransform: PropTypes.string
};
Input.defaultProps = {
  label: undefined,
  size: undefined,
  ellipsis: false,
  labelPosition: undefined,
  layout: undefined,
  showInfo: false,
  tooltipTitle: undefined,
  boldLabel: true,
  showTooltip: undefined,
  labelTextTransform: 'first-letter'
};

export default Input;
