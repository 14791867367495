import React from 'react';
import PropTypes from 'prop-types';
import PasswordIcon from './PasswordIcon';

const NPInputSuffix = ({ suffix, type, setShowPassword, showPassword }) => {
  return (
    <div className="input-suffix-wrapper">
      {suffix && <div className="input-suffix">{suffix}</div>}
      {type === 'password' && (
        <PasswordIcon
          setShowPassword={setShowPassword}
          showPassword={showPassword}
        />
      )}
    </div>
  );
};

NPInputSuffix.propTypes = {
  suffix: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.number
  ]),
  type: PropTypes.string,
  setShowPassword: PropTypes.func.isRequired,
  showPassword: PropTypes.bool
};
NPInputSuffix.defaultProps = {
  suffix: undefined,
  type: undefined,
  showPassword: false
};

export { NPInputSuffix as default, NPInputSuffix as InputSuffix };
